.stay-in-touch-container {
  padding: 12px;
  min-height: 100vh;
  background: #FBF8EE;
}

.stay-in-touch-container-header {
  color: #5F607B;
  font-family: TypoHoopBold, serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 4px;
  margin-bottom: 12px;
  text-align: center;
}

.stay-in-touch-header {
  color: #000;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 24px;
  font-style: bold;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
}

.stay-in-touch-img-container {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.stay-in-touch-container-discord {
  margin-bottom: 12px;
  margin-top: 15px;
  border-radius: 8px;
  padding: 16px;
  color: #FFF;
  font-family: TypoHoopRegular, serif;
  text-align: center;
  background:  #5865F2;
  margin-left:16px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stay-in-touch-txt {
  color: #000;
  text-align: justify;
  font-family: AntipastoRegular, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.25px;
  margin-bottom: 20px;
}

.stay-in-touch-txt {
  a {
    &:hover,
    &:link,
    &:visited {
      color: #000;
    }
  }
}